import * as React from "react";
import Img from "gatsby-image";
import Slider, { Settings } from "react-slick";
import { useMedia } from "use-media";
import Link from "../../LocalizedLink";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export type Impact = {
  uid: string;
  data: {
    title: {
      text: string;
    };
    cover: {
      localFile: any;
    };
  };
};

interface IProps {
  cases: Impact[];
  data: {
    cases: Array<{
      title: {
        text: string;
      };
      description: {
        text: string;
      };
    }>;
  };
}

const Impacts = (props: IProps) => {
  const isMobile = useMedia({ maxWidth: 960 });
  const data = props.data.cases[0];
  const settings: Settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    nextArrow: (
      <button>
        <span className="far fa-long-arrow-right" />
      </button>
    ),
    prevArrow: (
      <button>
        <span className="far fa-long-arrow-left" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          draggable: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      id="impact"
      className={`project-area ${
        isMobile ? "pt-50 pb-140 pl-10 pr-10" : "pt-125 pb-185 pl-140 pr-140"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 offset-lg-2 offset-xl-3">
            <div
              className={
                isMobile
                  ? "section-title text-center mb-50"
                  : "section-title text-center ml-50 mr-50 mb-75"
              }
            >
              <span className="border-left-1" />
              <span>{data.title.text}</span>
              <span className="border-right-1" />
              <h1>{data.description.text}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <Slider {...settings} className="project-active">
            {props.cases.map(impact => (
              <div key={impact.uid} className="col-xl-12">
                <div className="project-wrapper">
                  <div className="project-img">
                    <Link to={`/impacts/${impact.uid}`}>
                      <Img
                        fluid={
                          impact.data.cover.localFile.childImageSharp.fluid
                        }
                      />
                    </Link>
                    <div className="project-text">
                      {/* <span>business strategy</span> */}
                      <h3>
                        <Link to={`/impacts/${impact.uid}`}>
                          {impact.data.title.text}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Impacts;
