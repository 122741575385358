import * as React from "react";

interface IProps {
  data: {
    call_to_action: Array<{
      title: {
        text: string;
      };
      description: {
        text: string;
      };
      link: {
        url: string;
      };
      button_title: {
        text: string;
      };
      phone: {
        text: string;
      };
    }>;
  };
}

const CallToAction = (props: IProps) => {
  const data = props.data.call_to_action[0];
  return (
    <div
      id="cta"
      className="cta-area pt-125 pb-95"
      style={{ backgroundImage: `url(/images/bg/bg-2.jpg)` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-7">
            <div className="cta-text mb-30">
              <span>{data.title.text}</span>
              <h1>{data.description.text}</h1>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5">
            <div className="cta-button text-lg-right mb-30">
              <a
                className="btn btn-white btn-none"
                href={data.link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="btn-text">
                  {data.button_title.text}{" "}
                  <i className="far fa-long-arrow-right" />
                </span>
              </a>
              <a className="cta-link" href={`tel:${data.phone.text}`}>
                <i className="far fa-phone"></i> {data.phone.text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
